import React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Container, Box, Text, Link, Divider } from '@chakra-ui/react'
import Header from 'components/Header'

function Custom404() {
  return (
    <Container
      centerContent
      minH='100vh'
      display='flex'
      justifyContent='space-between'
      maxW='container.xl'
      pt={32}
      pb={4}
    >
      <Header title='Página no encontrada' />
      <Box mt={-8}>
        <Box position='relative' textAlign='center'>
          <Box
            display='flex'
            w='full'
            alignItems={'center'}
            justifyContent='center'
          >
            <Image
              src='/assets/icon-192x192.png'
              alt='Autoinspector Icon'
              width={60}
              height={60}
            />
          </Box>

          <Text
            mt={12}
            color='primary'
            fontSize='xl'
            fontWeight='semibold'
            textTransform='uppercase'
          >
            Error 404
          </Text>
        </Box>
        <Box textAlign='center'>
          <Text
            fontSize={['3xl', '4xl', '6xl']}
            color='black'
            fontWeight='bold'
          >
            Página no encontrada.
          </Text>
          <Text mb={2}>No pudimos encontrar la página que buscas</Text>
          <Link
            href='/'
            as={NextLink}
            color='primary'
            fontSize='xl'
            fontWeight='semibold'
          >
            Ir al inicio &rarr;
          </Link>
        </Box>
      </Box>
      <Box h='24px' display='flex' className='space-x-4'>
        <Link href='mailto:support@autoinspector.ai'>Soporte</Link>
        <Divider orientation='vertical' />
        <Link href='https://status.autoinspector.ai'>Status</Link>
      </Box>
      {/* <Box position='relative'>
        <Image
          src='/assets/svg/robot.svg'
          alt='Error robot'
          layout='intrinsic'
          width='600'
          height='600'
        />
        <Heading
          textAlign='center'
          fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
          position='absolute'
          top='60%'
          right={['35%', '37%', '37%', '35%']}
          color='white'
        >
          404
        </Heading>
      </Box>
      <Text fontWeight='bold' fontSize='3xl'>
        Página no encontrada
      </Text>
      <NextLink href='/'>
        <Link color='primary' mt='4'>
          Ir al inicio
        </Link>
      </NextLink> */}
    </Container>
  )
}

export default Custom404
